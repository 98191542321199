import React from 'react'

import Layout from '../../components/layout'
import Head from '../../components/head'

import Conspiracyposter from './conspiracyposter.jpg'

const ConspiracyposterPage = () => {
    return (
        <Layout image={Conspiracyposter} footerImageDescription='Surveillance - Poster Design'>
            <Head title="Surveillance -"/>
        </Layout>
    )
}

export default ConspiracyposterPage